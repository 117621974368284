/* eslint-disable vue/return-in-computed-property */
<template>
    <div class="app-subscriber-missing-permission">
        <div>
            <app-subscriber-header :title="loginTitle" />
            <div class="app-subscriber-missing-permission__email">{{ userEmail }}</div>
            <div class="app-subscriber-missing-permission__contents">
                <font-awesome-icon class="fa-solid app-subscriber-missing-permission__contents--ban" :icon="['far', 'ban']" />
                <div>{{ permissionMessage }}</div>
            </div>
            <div class="app-subscriber-missing-permission__actions">
                <tip-button id="back" :type="'primary'" :transparent="true" :loading="localIsLoading" @click="redirectToLogin">
                    <font-awesome-icon class="fa-angle-left app-subscriber-missing-permission__actions--angle-left" :icon="['far', 'angle-left']" />
                    {{ buttonTextBack }}
                    <template v-slot:loading>
                        <font-awesome-icon class="fa-spin" :icon="['far', 'spinner']" />
                    </template>
                </tip-button>
            </div>
        </div>
        <app-subscriber-footer />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import router from '@/router';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import AppSubscriberHeader from '@/components/AppSubscriber/AppSubscriberHeader';
import AppSubscriberFooter from '@/components/AppSubscriber/AppSubscriberFooter';

export default {
    name: 'AppSubscriberMissingPermission',
    components: {
        AppSubscriberHeader,
        AppSubscriberFooter
    },
    data() {
        return {
            localIsLoading: false
        };
    },
    computed: {
        ...mapState('login', ['userEmail', 'applicationName']),
        loginTitle() {
            return langConsts.titleLogin;
        },
        permissionMessage() {
            return `${langConsts.permissionMessage} ${this.applicationName}`;
        },
        buttonTextBack() {
            return langConsts.backText;
        }
    },
    methods: {
        redirectToLogin() {
            this.localIsLoading = true;
            router.replace(`${loginConsts.urls.baseSubscriberAppUrl}/${loginConsts.urls.urlPathLoginUserFlowEmail}`);
            return;
        }
    }
};
</script>

<style lang="scss">
.app-subscriber-missing-permission {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
    &__email {
        color: $outer-space;
        display: flex;
        justify-content: center;

        margin-top: -1.065rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.065rem;
    }
    &__contents {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        margin: 1.5rem 4.375rem 4rem 4.375rem;

        font-weight: 400;
        font-size: 0.75rem;
        line-height: 130%;

        color: $daintree;
        background-color: $lavender-blush;
        border-radius: 0.18rem;
        padding: 0.375rem 0.625rem 0.375rem 0.625rem;
        &--ban {
            color: $coral-red;
            margin-right: 0.625rem;
        }
    }
    &__actions {
        margin: 0 10.9rem 0 10.9rem;
        &--angle-left {
            margin-right: 0.25rem;
        }
    }
}
</style>
